import { UseQueryResult } from '@tanstack/react-query';
import forEach from 'lodash/forEach';

import { getJerseyNumber } from 'pages/backoffice/pages/games/game-form/components/selected-players';
import { transformGamePlayer } from 'pages/backoffice/pages/games/game-form/utils';
import { MetadataGame } from 'pages/backoffice/types/games';
import { TeamWithPlayers } from 'pages/backoffice/types/teams';
import { Player } from 'shared/types/player/player';

interface GetLatestPlayersFromGames {
  team?: TeamWithPlayers;
  games?: UseQueryResult<MetadataGame>[];
}

export const getLatestPlayersFromGames = ({ team, games }: GetLatestPlayersFromGames): Player[] | undefined => {
  if (!team || !games) return;

  const latestUniquePlayersFromGames = games.reduce<Record<string, Player>>((acc, game) => {
    if (!game.data) return acc;

    const gamePlayers: Player[] = game.data.players
      .filter((player) => player.teamId === team.id)
      .map((player) => transformGamePlayer(player, team, game.data));

    forEach(gamePlayers, (player) => {
      const playerFixturesDateInAcc = acc[player.id]?.fixtures?.find((item) => item.date);
      const playerFixturesDateInPlayer = player.fixtures?.find((item) => item.date);
      if (
        !acc[player.id] ||
        (acc[player.id] &&
          playerFixturesDateInAcc &&
          playerFixturesDateInPlayer &&
          playerFixturesDateInAcc.date !== null &&
          playerFixturesDateInPlayer.date !== null &&
          Date.parse(playerFixturesDateInAcc.date) < Date.parse(playerFixturesDateInPlayer.date))
      ) {
        acc[player.id] = player;
      }
    });

    return acc;
  }, {});

  const sortByJerseyNumber = (a: Player, b: Player) => getJerseyNumber(a) - getJerseyNumber(b);
  return Object.values(latestUniquePlayersFromGames).sort(sortByJerseyNumber);
};
