import { Grid } from '@mui/material';

import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';

type MatchDayInfoProps = {
  matchDay: string;
};

const MatchDayInfo = ({ matchDay }: MatchDayInfoProps) => {
  if (!matchDay) return null;

  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingCardTag}>
      {matchDay}
    </Grid>
  );
};

export default MatchDayInfo;
