import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerWithDefaults } from 'api/routes';
import { Player } from 'shared/types/player/player';
import { transformPlayerApiResponse } from 'shared/utils/transformPlayer';

const PLAYER_WITH_DEFAULTS_QUERY_KEY = 'player-with-defaults';

export const usePlayerWithDefaults = (playerId: string): UseSuspenseQueryResult<Player> => {
  return useFetchSuspenseRequest<Player>({
    url: playerWithDefaults(playerId),
    queryRef: [PLAYER_WITH_DEFAULTS_QUERY_KEY, playerId],
    transformer: transformPlayerApiResponse,
  });
};
