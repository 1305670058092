import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { useState } from 'react';

import { EditPlayerModal } from 'pages/backoffice/pages/players/components/edit-player-modal';
import { PlayerDetailsModal } from 'pages/backoffice/pages/players/components/player-details-modal';
import { PlayerMappingsModal } from 'pages/backoffice/pages/players/components/player-mappings-modal';
import { PlayersListItem } from 'pages/backoffice/pages/players/components/players-list/styled';
import { IconTimeline } from 'shared/components/icons';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { PlayerPhoto } from 'shared/components/player-photo/PlayerPhoto';
import { Player } from 'shared/types/player/player';

interface Props {
  player: Player;
}

export const PlayerItem = ({ player }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isMappingModalOpen, setMappingModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);
  const handleMappingModalOpen = () => setMappingModalOpen(true);
  const handleMappingsModalClose = () => setMappingModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleDetailsModalOpen,
    },
  ];

  return (
    <>
      <PlayersListItem key={player.id}>
        <PlayerPhoto src={player.photoUrl} />
        <Typography marginLeft={2} fontWeight={fontWeight['500']}>
          {player.name}
        </Typography>
        <KebabMenu options={options} />
      </PlayersListItem>
      {isEditModalOpen ? (
        <EditPlayerModal open={isEditModalOpen} onClose={handleEditModalClose} player={player} playerId={player.id} />
      ) : null}
      {isDetailsModalOpen && player?.fixtures ? (
        <PlayerDetailsModal
          playerMappings={player.mappings}
          playerFixtures={player.fixtures}
          onClose={handleDetailsModalClose}
        />
      ) : null}
      {isMappingModalOpen && player ? <PlayerMappingsModal player={player} onClose={handleMappingsModalClose} /> : null}
    </>
  );
};
