import { boolean, number, object, string, array, z } from 'zod';

import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';
import { PlayerMappingProviders } from 'shared/types/player/player';
import { TeamTypes } from 'shared/types/team/team';

const playerTeamSchema = object({
  id: string(),
  name: string(),
  abbreviation: string().optional().nullable(),
  logoUrl: string().optional().nullable(),
  countryCode: string().optional(), // TODO: change type after changing types for team
  teamType: z.enum([TeamTypes.CLUB, TeamTypes.NATIONAL_TEAM]),
});

const playerCompetitionSchema = object({
  id: string(),
  name: string(),
  country: string(),
  seasons: array(
    object({
      id: string(),
      year: number(),
      crossesYear: boolean(),
      name: string(),
    }),
  ),
});

const playerSeasonSchema = object({
  id: string(),
  year: number(),
  crossesYear: boolean(),
  name: string(),
  competition: object({
    id: string(),
    name: string(),
    country: string(),
  }),
  stages: array(
    object({
      id: string(),
      name: string(),
      orderIndex: number().nullable(),
      childStages: object({
        id: string(),
        name: string(),
        orderIndex: number().nullable(),
      }),
      parentStage: object({
        id: string(),
        name: string(),
        orderIndex: number().optional().nullable(),
      })
        .optional()
        .nullable(),
    }),
  ),
});

export const playerFixturesSchema = object({
  position: number().min(0).max(10),
  side: number().min(0).max(7),
  jerseyNumber: number().min(0),
  name: string(),
  homeTeamScore: number(),
  awayTeamScore: number(),
  homePenaltyScore: number(),
  awayPenaltyScore: number(),
  matchEndType: z.enum([
    FixtureMatchEndType.EXTENDED_TIME,
    FixtureMatchEndType.GOLDEN_GOAL_RULE,
    FixtureMatchEndType.NORMAL_TIME,
    FixtureMatchEndType.PENALTY_SHOOT_OUT,
  ]),
  id: string(),
  team: playerTeamSchema,
  date: string().nullable(),
});

export const playerMappingSchema = object({
  provider: z.enum([
    PlayerMappingProviders.BUNDES_LIGA,
    PlayerMappingProviders.CHYRONHEGO,
    PlayerMappingProviders.HAWKEYE,
    PlayerMappingProviders.KOGNIA,
    PlayerMappingProviders.OPTA,
    PlayerMappingProviders.SECOND_SPECTRUM,
    PlayerMappingProviders.SIFNALITY,
    PlayerMappingProviders.SIFNALITY_KOGNIA,
    PlayerMappingProviders.SPORT_LOGIQ,
  ]),
  playerIdProvider: string(),
});

const playerApiDefaultsSchema = object({
  team: playerTeamSchema,
  season: playerSeasonSchema,
  competition: playerCompetitionSchema,
});

export const playerSchema = object({
  name: string(),
  id: string(),
  photoUrl: string().optional().nullable(),
  fixtures: array(playerFixturesSchema),
  mappings: array(playerMappingSchema),
  playerDefaults: playerApiDefaultsSchema.optional(),
});
