import { zodResolver } from '@hookform/resolvers/zod';
import { Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import map from 'lodash/map';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TypeOf } from 'zod';

import { useEditPlayer } from 'pages/backoffice/api/players/use-edit-player';
import { useInvalidatePlayers } from 'pages/backoffice/api/players/use-players';
import { MappingProvider } from 'pages/backoffice/types/shared';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import { DialogNew } from 'shared/components/dialog-new';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { IconUser } from 'shared/components/icons/icon-user';
import { Player, PlayerMappingProviders } from 'shared/types/player/player';
import { playerMappingSchema } from 'shared/types/player/playerSchema';

interface Props {
  onClose: () => void;
  player: Player;
}

const defaultProvider = PlayerMappingProviders.OPTA;

const findPlayerProviderMapping = (player: Player, mappingProvider: string) => {
  if (!player?.mappings) return null;

  const mapping = player.mappings.find((mapping) => mapping.provider === mappingProvider);
  return mapping ? mapping.playerIdProvider : null;
};

export enum MappingFormSchemaNames {
  provider = 'provider',
  playerIdProvider = 'playerIdProvider',
}

export type MappingFormSchema = TypeOf<typeof playerMappingSchema>;

export const PlayerMappingsModal = ({ onClose, player }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidatePlayers = useInvalidatePlayers();
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const { editPlayer, isPending } = useEditPlayer();

  const {
    register,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<MappingFormSchema>({
    resolver: zodResolver(playerMappingSchema),
    defaultValues: {
      [MappingFormSchemaNames.provider]: defaultProvider,
      [MappingFormSchemaNames.playerIdProvider]: findPlayerProviderMapping(player, defaultProvider) || '',
    },
  });

  const playerIdProvider = watch(MappingFormSchemaNames.playerIdProvider);

  const editPlayerAction = useCallback(
    (playerId: string, mapping: MappingFormSchema) => {
      editPlayer({
        playerId,
        data: {
          mappings: [{ player_id_provider: mapping.playerIdProvider, provider: mapping.provider }],
        },
        onSuccess: () => {
          invalidatePlayers().then(() => onClose());
        },
      });
    },
    [editPlayer, invalidatePlayers, onClose],
  );

  const handleEditMapping = useCallback(
    (mapping: MappingFormSchema) => {
      findPlayerProviderMapping(player, mapping.provider) && !isOverwriteModalOpen
        ? setIsOverwriteModalOpen(true)
        : editPlayerAction(player.id, mapping);
    },
    [isOverwriteModalOpen, editPlayerAction, player],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      fullWidth
      maxWidth='md'
      title={'Player mappings'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Done'}
      submitDisabled={!isDirty}
      isLoading={isPending}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleEditMapping)}>
        <Stack gap={2}>
          <FormFormLabel>Provider</FormFormLabel>
          <Select size={'small'} variant={'outlined'} value={defaultProvider}>
            {map(Object.keys(MappingProvider), (providerKey: keyof typeof MappingProvider) => (
              <MenuItem key={providerKey} value={MappingProvider[providerKey]}>
                {MappingProvider[providerKey]}
              </MenuItem>
            ))}
          </Select>
          <FormFormLabel>Mapping</FormFormLabel>
          <Stack direction={'row'} spacing={1}>
            <TextField
              sx={{ flexGrow: 1 }}
              size='small'
              {...register(MappingFormSchemaNames.playerIdProvider)}
              error={!!errors[MappingFormSchemaNames.playerIdProvider]}
              helperText={
                errors[MappingFormSchemaNames.playerIdProvider] && (
                  <>{errors[MappingFormSchemaNames.playerIdProvider].message}</>
                )
              }
            />
            {Boolean(playerIdProvider) && Boolean(findPlayerProviderMapping(player, defaultProvider)) && (
              <Button size={'small'} variant={'contained'} color={'error'} onClick={() => setIsRemoveModalOpen(true)}>
                Remove
              </Button>
            )}
          </Stack>
        </Stack>
        {isOverwriteModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Overwrite'}
            description={'This action will overwrite the existing mapping'}
            isOpen={isOverwriteModalOpen}
            onConfirm={() => formRef.current?.requestSubmit()}
            setIsOpen={setIsOverwriteModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
        {isRemoveModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Remove'}
            description={'This action will remove the existing mapping'}
            isOpen={isRemoveModalOpen}
            onConfirm={() => editPlayerAction(player.id, { provider: defaultProvider, playerIdProvider: '' })}
            setIsOpen={setIsRemoveModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
      </form>
    </DialogNew>
  );
};
