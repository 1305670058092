import { z } from 'zod';

import { Venue } from 'pages/backoffice/api/venues/use-venues/types';
import { Coach } from 'pages/backoffice/types/coaches';
import { MetadataRecordingSummary } from 'pages/backoffice/types/recordings';
import { Season } from 'pages/backoffice/types/seasons';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';
import { playerFixturesSchema, playerMappingSchema } from 'shared/types/player/playerSchema';

export const metadataTeamSchema = z.object({
  name: z.string(),
  abbreviation: z.string(),
  logoUrl: z.string().optional().nullable(),
  teamType: z.string(),
  countryCode: z.string().optional(),
  id: z.string(),
});

export type MetadataTeam = z.TypeOf<typeof metadataTeamSchema>;

export const metadataFixturesPlayerSchema = z.object({
  position: z.number(),
  side: z.number(),
  jerseyNumber: z.number(),
  name: z.string(),
  id: z.string(),
  photoUrl: z.string().optional().nullable(),
  teamId: z.string(),
  mappings: z.array(playerMappingSchema).optional().nullable(),
});

export type MetadataFixturePlayer = z.infer<typeof metadataFixturesPlayerSchema>;

export const metadataGamePlayerSchema = z.object({
  position: z.number().optional(),
  side: z.number().optional(),
  jerseyNumber: z.number().optional(),
  name: z.string(),
  id: z.string(),
  photoUrl: z.string().optional().nullable(),
  teamId: z.string().optional(),
  mappings: z.array(playerMappingSchema).optional().nullable(),
  fixtures: z.array(playerFixturesSchema).optional(),
});

export type MetadataGamePlayer = z.infer<typeof metadataGamePlayerSchema>;

export type MetadataGame = {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date?: string;
  pitchLength: number;
  pitchWidth: number;
  homePenaltyScore: number;
  awayPenaltyScore: number;
  matchEndType: FixtureMatchEndType;
  id: string;
  homeTeam: MetadataTeam;
  awayTeam: MetadataTeam;
  homeCoach?: Coach;
  awayCoach?: Coach;
  venue?: Venue;
  stage: {
    name: string;
    orderIndex: number;
    id: string;
  };
  season: Season;
  players: MetadataFixturePlayer[];
  recordings: MetadataRecordingSummary[];
};
