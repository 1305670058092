import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import queryString from 'query-string';

import { ClientsFilters } from 'api/backoffice/client/hooks/use-clients/types';
import { KogniaUsersFilters } from 'api/backoffice/user/use-kognia-users/types';
import { apiHost } from 'api/config';
import { SharedQueryOptions } from 'api/hooks/useInfinityQuery/types';
import { PlaylistsWithFiltersEndpointFilters } from 'api/playlist/types';
import { PlaylistDownloadRequestsParams } from 'api/playlist/useDownloadPlaylist/types/playlistDownloadRequestsParams';
import { RecordingEndpointFilters } from 'api/recording/useFetchRecordings';
import { generateQueryParamsFromOptionsAndFilters } from 'api/utils';
import { VODVideoSourcesFilters } from 'api/video-sources/use-fetch-vod-video-sources';
import { RecordingCompetitionsFilters } from 'pages/backoffice/api/season-clients/use-recording-competitions/types';
import { CONNECTION_QUERY_PARAM_NAME, RETURN_TO_QUERY_PARAM_NAME } from 'shared/constants/queryParams';
import { ENVIRONMENT } from 'shared/utils/env-variables';

export const FeatureFlagsUrl = `${apiHost}/feature-toggles`;

export const matchWithEpisodesUrl = (recordingId: string) => `${apiHost}/match-with-episodes/${recordingId}`;
export const liveTaggingSessionAsMatchUrl = (liveTaggingSessionId: string) =>
  `${apiHost}/live-tagging-session-as-match/${liveTaggingSessionId}`;

export const tacticalAnalysesAsItems = `${apiHost}/tactical-analyses-playlist-items`;
export const tacticalAnalysisWithFilters = (tacticalAnalysisId: string) =>
  `${apiHost}/tactical-analyses/${tacticalAnalysisId}/episodes`;
export const tacticalAnalysesFilters = (recordingIds: string[]) => {
  return queryString.stringifyUrl(
    {
      url: `${apiHost}/tactical-analyses-filters`,
      query: { recordingIds },
    },
    { arrayFormat: 'comma' },
  );
};

// keypads
export const keypadsUrl = () => `${apiHost}/keypad`;
export const keypadUrl = (id: string) => `${apiHost}/keypad/${id}`;
export const keypadCopyUrl = (id: string) => `${apiHost}/keypad/${id}/copy`;
export const keypadTagsUrl = (keypadId: string) => `${keypadsUrl()}/${keypadId}/tag`;
export const deleteKeypadTagsUrl = (keypadId: string, tagId: string) => `${keypadsUrl()}/${keypadId}/tag/${tagId}`;

// playlists
export const playlistsUrl = `${apiHost}/playlists`;
export const playlistUrl = (id: string) => `${apiHost}/playlists/${id}`;
export const duplicatePlaylistUrl = (playlistId: string) => `${apiHost}/playlists/${playlistId}/copy`;
export const recordingPlaylistsUrl = (id: string, includeEmpty = true) => {
  const includeEmptyParam = includeEmpty ? '' : '&includeEmpty=false';
  return `${apiHost}/playlists?recordingId=${id}${includeEmptyParam}`;
};
export const tacticsInRange = `${apiHost}/tactical-analyses/tactics-in-range`;

export const playlistDownloadRequestsUrl = ({
  showTitles,
  showOverlays,
  joinSources,
}: PlaylistDownloadRequestsParams) =>
  `${apiHost}/playlist-download-requests?showTitles=${showTitles}&showOverlays=${showOverlays}&joinSources=${joinSources}`;
export const playlistDownloadRequestsPlaylistIdUrl = ({
  playlistId,
  joinSources,
  showOverlays,
  showTitles,
}: {
  playlistId: string;
} & PlaylistDownloadRequestsParams) =>
  `${apiHost}/playlist-download-requests/${playlistId}?joinSources=${joinSources}&showOverlays=${showOverlays}&showTitles=${showTitles}&timeZoneId=${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
export const playlistDownloadRequestsPlaylistItemsUrl = ({
  playlistId,
  joinSources,
  showOverlays,
  showTitles,
}: {
  playlistId: string;
} & PlaylistDownloadRequestsParams) =>
  `${apiHost}/playlist-download-requests/${playlistId}/playlistItems?joinSources=${joinSources}&showOverlays=${showOverlays}&showTitles=${showTitles}&timeZoneId=${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
export const downloadEffectiveTimeVideosUrl = () =>
  `${apiHost}/effective-time-download-requests?timeZoneId=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

export const playlistsWithFiltersUrl = (filters: PlaylistsWithFiltersEndpointFilters) => {
  return queryString.stringifyUrl({
    url: `${apiHost}/playlists-with-filters`,
    query: reduce(filters, (result, filter, key) => (filter ? { ...result, [key]: filter } : { ...result }), {}),
  });
};

export const playlistItemsBaseUrl = (playlistId: string) => `${apiHost}/playlists/${playlistId}/playlist-items`;
export const deletePlaylistItemUrl = (playlistId: string, playlistItemId: string) =>
  `${apiHost}/playlists/${playlistId}/playlist-items/${playlistItemId}`;
export const deletePlaylistItemsUrl = (playlistId: string) => `${apiHost}/playlists/${playlistId}/playlist-items`;
export const updatePlaylistItemUrl = (playlistId: string, playlistItemId: string) =>
  `${apiHost}/playlists/${playlistId}/playlist-items/${playlistItemId}`;
export const postManyPlaylistItem = (playlistId: string) => `${apiHost}/playlists/${playlistId}/playlist-items`;
export const duplicatePlaylistItemsUrl = (playlistId: string) => `${playlistItemsBaseUrl(playlistId)}/copy`;
export const downloadPlaylistXmlUrl = (playlistId: string) => `${apiHost}/playlists/${playlistId}/xml`;
export const playlistPreview = `${apiHost}/playlists/preview`;

// presigned uploads
export const initiateMultipartUploadUrl = (recordingId: string) =>
  `${apiHost}/initiate-multipart-upload/${recordingId}`;
export const getMultipartUploadUrl = (recordingId: string, uploadId: string, partNumber: number, partLength: number) =>
  `${apiHost}/get-multipart-upload-url/${recordingId}?uploadId=${uploadId}&partNumber=${partNumber}&partLength=${partLength}`;
export const completeMultipartUploadUrl = (recordingId: string) =>
  `${apiHost}/complete-multipart-upload/${recordingId}`;
export const abortMultipartUploadUrl = (recordingId: string, uploadId: string) =>
  `${apiHost}/abort-multipart-upload/${recordingId}?uploadId=${uploadId}`;

// recordings
export const recordingsBaseUrl = `${apiHost}/recordings`;
export const recordingsListUrl = (filters: RecordingEndpointFilters) => {
  return queryString.stringifyUrl({
    url: recordingsBaseUrl,
    query: reduce(filters, (result, filter, key) => ({ ...result, [key]: filter }), {}),
  });
};
export const specificRecordingUrl = (id: string) => `${recordingsBaseUrl}/${id}`;
export const recordingsByNameUrl = `${apiHost}/recordings-by-name`;

// tactical analyses
export const tacticalAnalysesBaseUrl = `${apiHost}/tactical-analyses`;
export const tacticalAnalysesInRecordings = (recordingIds: string[]) => {
  return queryString.stringifyUrl(
    {
      url: tacticalAnalysesBaseUrl,
      query: { recordingIds },
    },
    { arrayFormat: 'comma' },
  );
};
export const invalidateTacticalAnalysisUrl = (tacticalAnalysisId: string) =>
  `${tacticalAnalysesBaseUrl}/${tacticalAnalysisId}/invalidate`;
export const validateTacticalAnalysisUrl = (tacticalAnalysisId: string) =>
  `${tacticalAnalysesBaseUrl}/${tacticalAnalysisId}/validate`;

// tagging-tool
export const taggingRecordingsBaseUrl = `${apiHost}/tagging-recordings`;
export const liveSessionsWithFiltersBaseUrl = `${apiHost}/live-tagging-sessions-with-filters`;
export const liveSessionsWithFiltersUrl = (filters: any) => {
  return queryString.stringifyUrl({
    url: liveSessionsWithFiltersBaseUrl,
    query: reduce(filters, (result, filter, key) => ({ ...result, [key]: filter }), {}),
  });
};
export const taggingEventBaseUrl = `${apiHost}/tagging-events`;
export const matchOrLiveTaggingEventsUrl = (recordingId: string, isLive: boolean) =>
  `${apiHost}/tagging-events-data?referenceId=${recordingId}&isLive=${isLive}`;
export const matchTaggingEventUrl = (taggingEventId: string) => `${taggingEventBaseUrl}/${taggingEventId}`;
export const cropTaggingEventUrl = (taggingEventId: string) => `${taggingEventBaseUrl}/${taggingEventId}/crop`;
export const editAllAlignmentsUrl = (recordingId: string) =>
  `${taggingEventBaseUrl}/edit-alignment?recordingId=${recordingId}`;
export const alignAllWithRecordingUrl = `${taggingEventBaseUrl}/align-with-recording`;
export const liveTaggingSessionsBaseUrl = `${apiHost}/live-tagging-sessions`;
export const liveTaggingSessionUrl = (id: string) => `${liveTaggingSessionsBaseUrl}/${id}`;
export const importTaggingEventUrl = (recordingId: string) => `${apiHost}/import/${recordingId}`;
export const exportTaggingEventUrl = (recordingId: string, isLive: boolean) =>
  `${apiHost}/export/${recordingId}?isLive=${isLive}`;

// teams
export const teamUrl = `${apiHost}/teams`;

// users
export const userBatchClientIdsUrl = `${apiHost}/users/batch-client-ids`;
export const userAuth0Url = `${apiHost}/users/create-auth0`;
export const userIdUrl = (id: string) => `${apiHost}/users/${id}`;
export const userUrl = `${apiHost}/users`;
export const usersWithFiltersUrl = (options: SharedQueryOptions, filters: KogniaUsersFilters) => {
  return queryString.stringifyUrl({
    url: userUrl,
    query: generateQueryParamsFromOptionsAndFilters(options, filters),
  });
};

const loginUrl = `${apiHost}/oauth2/authorization/oidc`;
export const returnToQueryParam = `${location.protocol}//${location.host}`;
export const logoutUrl = `${userUrl}/logout`;
export const AccountUrl = `${apiHost}/account`;
export const VODVideoSourcesUrl = (filters: VODVideoSourcesFilters) => {
  return queryString.stringifyUrl({
    url: `${apiHost}/vod-video-sources`,
    query: reduce(filters, (result, filter, key) => ({ ...result, [key]: filter }), {}),
  });
};
export const getOauth2LoginUrl = (path = '/', connection: string | null) => {
  const queryParams = [];
  const port =
    ENVIRONMENT === 'development' || (ENVIRONMENT === 'production' && location.hostname === 'localhost') ? ':8280' : '';

  queryParams.push(`${RETURN_TO_QUERY_PARAM_NAME}=${encodeURIComponent(path)}`);
  if (connection) queryParams.push(`${CONNECTION_QUERY_PARAM_NAME}=${connection}`);

  return `${location.protocol}//${location.hostname}${port}${loginUrl}?${queryParams.join('&')}`;
};

// XML downloads
export const XMLDownloadUrl = (recordingId: string, tacticalAnalysisId?: string, isEffectiveTime = false) => {
  return queryString.stringifyUrl({
    url: `${apiHost}/nacsport-xml/${recordingId}`,
    query: { tacticalAnalysisId: tacticalAnalysisId, isEffectiveTime: isEffectiveTime ? 'true' : undefined },
  });
};

// Clients
export const clientBaseUrl = `${apiHost}/clients`;
export const clientEditUrl = (id: string) => `${clientBaseUrl}/${id}`;
export const clientsWithFiltersUrl = (options: SharedQueryOptions, filters: ClientsFilters) => {
  return queryString.stringifyUrl({
    url: clientBaseUrl,
    query: generateQueryParamsFromOptionsAndFilters(options, filters),
  });
};

// Clients Branding
export const clientBrandingUrl = (clientId: string) => `${clientBaseUrl}/${clientId}/branding`;

export const recordingCompetitionsBaseUrl = `${apiHost}/competitions`;

export const recordingCompetitionsWithFiltersUrl = (
  options: SharedQueryOptions,
  filters: RecordingCompetitionsFilters,
) => {
  return queryString.stringifyUrl({
    url: recordingCompetitionsBaseUrl,
    query: generateQueryParamsFromOptionsAndFilters(options, filters),
  });
};

export const userPresetsBaseUrl = `${apiHost}/user-presets`;

export const userPresetsWithFiltersUrl = ({ scope, key, ref }: { scope: string; key?: string; ref?: string }) => {
  return queryString.stringifyUrl({
    url: userPresetsBaseUrl,
    query: { scope, key, ref },
  });
};

// Dashboarding
export const dashboardGuestToken = (dashboardId: string) => `${apiHost}/superset-auth?dashboardId=${dashboardId}`;

// Download manager
export const downloadRequestsUrl = `${apiHost}/download-requests`;
export const latestViewedDownloadRequestsUrl = `${apiHost}/latest-viewed-download-request`;
export const generateDownloadRequestsUrl = (
  options: Omit<SharedQueryOptions, 'sortDirection'> & { viewed?: boolean },
) => {
  return queryString.stringifyUrl({
    url: downloadRequestsUrl,
    query: reduce(options, (result, option, key) => ({ ...result, [key]: option }), {}),
  });
};

// Sports metadata api (proxied through backend api)
export const playerWithDefaults = (playerId: string) => `${apiHost}/players/${playerId}`;
export const playerCompetitions = (playerId: string) => `${apiHost}/players/${playerId}/competitions`;
export const playersUrl = `${apiHost}/players`;
export const generatePlayersUrl = (options: SharedQueryOptions, filters: { name?: string }) => {
  return queryString.stringifyUrl({
    url: playersUrl,
    query: generateQueryParamsFromOptionsAndFilters(omit(options, 'sortDirection'), filters),
  });
};
