import pickBy from 'lodash/pickBy';

import { RecordingAnnotationTypes, RecordingFilters } from 'pages/recordings-list/types';
import { FiltersList } from 'shared/types/filters/types';

export const transformFiltersForRequest = (filters: FiltersList) => {
  const annotationType = filters[RecordingFilters.ANNOTATION_TYPE]
    ? Object.keys(pickBy(filters[RecordingFilters.ANNOTATION_TYPE].options, (option) => option.isApplied))[0]
    : RecordingAnnotationTypes.all;

  const competition = filters[RecordingFilters.COMPETITION]
    ? Object.keys(pickBy(filters[RecordingFilters.COMPETITION].options, (option) => option.isApplied))
    : [];

  const date = filters[RecordingFilters.DATE]
    ? Object.keys(pickBy(filters[RecordingFilters.DATE].options, (option) => option.isApplied))
    : [];

  const matchday = filters[RecordingFilters.MATCHDAY]
    ? Object.keys(pickBy(filters[RecordingFilters.MATCHDAY].options, (option) => option.isApplied))
    : [];

  const teamId = filters[RecordingFilters.TEAM]
    ? Object.keys(pickBy(filters[RecordingFilters.TEAM].options, (option) => option.isApplied))
    : [];

  let search = `${RecordingFilters.ANNOTATION_TYPE}=${annotationType}`;

  if (competition && competition.length > 0) search = search.concat(`&${RecordingFilters.COMPETITION}=${competition}`);
  if (date && date.length > 0) search = search.concat(`&${RecordingFilters.DATE}=${date}`);
  if (matchday && matchday.length > 0) search = search.concat(`&${RecordingFilters.MATCHDAY}=${matchday}`);
  if (teamId && teamId.length > 0) search = search.concat(`&${RecordingFilters.TEAM}=${teamId}`);

  return {
    filters: {
      competition: `${competition}`,
      date: `${date}`,
      matchday: `${matchday}`,
      teamId: `${teamId}`,
      ...(annotationType == RecordingAnnotationTypes.all ? {} : { annotationType }),
    },
    search,
  };
};
