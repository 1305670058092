import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformPlayers } from 'api/player/use-fetch-players/utils/playerTransformer';
import { generatePlayersUrl } from 'api/routes';
import { SortDirection } from 'shared/types/filters/types';
import { PlayerBackendApiResponse, PlayerApiFilters, Player } from 'shared/types/player/player';

const FETCH_PLAYERS_QUERY_KEY = 'fetchPlayers';

export const useFetchPlayers = (): InfinityQueryResult<Player, PlayerApiFilters> => {
  return useInfinityQuery<Player, PlayerApiFilters, PlayerBackendApiResponse>({
    generateUrl: generatePlayersUrl,
    key: FETCH_PLAYERS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    initialFilters: {
      name: '',
    },
    transformer: transformPlayers,
    queryOptions: {
      enabled: true,
    },
  });
};
