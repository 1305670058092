import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useCallback, useMemo } from 'react';

import { usePlayersMappings } from 'pages/backoffice/api/use-players-mappings';
import { PlayerListHeader } from 'pages/backoffice/pages/games/game-form/components/player-list/player-list-header';
import {
  getJerseyNumber,
  getTeamLastGameDate,
  getTeamName,
} from 'pages/backoffice/pages/games/game-form/components/selected-players';
import { MetadataGamePlayer } from 'pages/backoffice/types/games';
import { PlayerPhoto } from 'shared/components/player-photo/PlayerPhoto';
import Spinner from 'shared/components/spinner';
import { useDates } from 'shared/hooks/use-dates';

type Props = {
  latestPlayers: MetadataGamePlayer[];
  selectedPlayers: MetadataGamePlayer[];
  addPlayers: (player: MetadataGamePlayer[]) => void;
  removePlayers: (player: MetadataGamePlayer[]) => void;
  displayOption?: 'remove' | 'checkbox';
  isLoading: boolean;
};

export const SelectableListOfPlayers = ({
  latestPlayers,
  selectedPlayers = [],
  addPlayers,
  removePlayers,
  displayOption,
  isLoading,
}: Props) => {
  const { data: mappings, isLoading: isMappingsLoading } = usePlayersMappings();
  const { dateToString } = useDates();
  const handleCheckboxChange = useCallback(
    (player: MetadataGamePlayer) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.checked ? addPlayers([player]) : removePlayers([player]);
    },

    [addPlayers, removePlayers],
  );

  const toggleAllPlayers = useCallback(() => {
    selectedPlayers.length === 0 ? addPlayers(latestPlayers) : removePlayers(latestPlayers);
  }, [selectedPlayers, latestPlayers, addPlayers, removePlayers]);

  const playerIds = useMemo(() => selectedPlayers.map((player) => player.id), [selectedPlayers]);

  const isChecked = selectedPlayers.length === latestPlayers.length && selectedPlayers.length > 0;
  const isIndeterminate = selectedPlayers.length > 0 && selectedPlayers.length < latestPlayers.length;

  if ((isLoading || isMappingsLoading) && selectedPlayers.length === 0)
    return (
      <Stack justifyContent={'center'} alignItems={'center'} sx={{ color: Colors.storm, padding: 2 }}>
        <Spinner />
      </Stack>
    );

  if (latestPlayers.length === 0)
    return <Box sx={{ color: Colors.storm, padding: 2 }}>No players available for this game</Box>;

  return (
    <Stack gap={1}>
      <PlayerListHeader />
      {displayOption === 'checkbox' ? (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Checkbox checked={isChecked} indeterminate={isIndeterminate} onChange={toggleAllPlayers} />

          <label style={{ lineHeight: 1 }}>{isChecked || isIndeterminate ? 'Unselect all' : 'Select all'}</label>
        </Stack>
      ) : null}
      <Stack>
        {latestPlayers.map((player) => {
          const teamName = getTeamName(player);
          const lastGameDate = getTeamLastGameDate(player);
          const formattedLastGameDate = lastGameDate ? dateToString(lastGameDate) : undefined;
          const position = player.position ?? undefined;
          const side = player.side ?? undefined;
          const jerseyNumber = getJerseyNumber(player);

          return (
            <Box
              key={`last-games-${player.id}`}
              sx={{
                borderBottom: `1px solid ${Colors.athens}`,
                padding: 1,
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: '32px 1.5fr 170px 170px 100px 2fr',
              }}
            >
              <Checkbox
                checked={playerIds.includes(player.id)}
                color='secondary'
                onChange={handleCheckboxChange(player)}
              />
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <PlayerPhoto src={player.photoUrl} />
                <Typography sx={{ minWidth: '100px' }}>{player.name}</Typography>
              </Stack>
              <Typography sx={{ minWidth: '100px' }}>
                {mappings && position ? `${mappings.positions[position]} (${position})` : ''}
              </Typography>
              <Typography sx={{ minWidth: '100px' }}>
                {mappings && side ? `${mappings.sides[side]} (${side}}` : ''}
              </Typography>
              <Typography sx={{ minWidth: '100px' }}>{jerseyNumber}</Typography>
              {teamName && formattedLastGameDate ? (
                <Typography sx={{ wrap: 'no-wrap' }}>
                  {teamName} / {formattedLastGameDate}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};
