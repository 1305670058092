import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useEditPlayer } from 'pages/backoffice/api/players/use-edit-player';
import { useInvalidatePlayers } from 'pages/backoffice/api/players/use-players';
import { PlayerForm } from 'pages/backoffice/pages/players/components/player-form';
import { editPlayerFormSchema } from 'pages/backoffice/pages/players/components/player-form/form';
import {
  EditPlayerForm,
  EditPlayerFormSchema,
  PlayerFormFieldsNames,
} from 'pages/backoffice/pages/players/components/player-form/types';
import { PlayerPhotoPreview } from 'pages/backoffice/pages/players/components/player-photo-preview';
import { DialogNew } from 'shared/components/dialog-new';
import IconEdit from 'shared/components/icons/icon-edit';
import { Player } from 'shared/types/player/player';

interface Props {
  open: boolean;
  onClose: () => void;
  player: Player;
  playerId: string;
}

export const EditPlayerModal = ({ open, onClose, player, playerId }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidatePlayers = useInvalidatePlayers();
  const { editPlayer } = useEditPlayer();

  const initialValues = {
    [PlayerFormFieldsNames.name]: player.name,
  };

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    trigger,
  } = useForm<EditPlayerFormSchema>({
    resolver: zodResolver(editPlayerFormSchema),
    defaultValues: initialValues,
  });

  const playerImage = watch(PlayerFormFieldsNames.playerImage);
  const photoUrlValue =
    playerImage && playerImage.length > 0 ? URL.createObjectURL(playerImage[0]) : player ? player.photoUrl : undefined;

  const handleEditPlayer = useCallback(
    (player: EditPlayerForm) => {
      editPlayer({
        playerId: playerId,
        data: {
          name: player[PlayerFormFieldsNames.name],
          image: player[PlayerFormFieldsNames.playerImage] ? player[PlayerFormFieldsNames.playerImage][0] : undefined,
        },
        onSuccess: () => {
          invalidatePlayers();
          onClose();
        },
      });
    },
    [editPlayer, invalidatePlayers, onClose, playerId],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='Edit player'
      icon={<IconEdit size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Done'}
      open={open}
      submitDisabled={!isDirty}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleEditPlayer)}>
        <PlayerForm<EditPlayerFormSchema> register={register} errors={errors} trigger={trigger} />
        <PlayerPhotoPreview photoUrl={photoUrlValue} />
      </form>
    </DialogNew>
  );
};
