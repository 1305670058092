import { useCallback, useRef } from 'react';

import { useCreateUser } from 'api/backoffice/user/use-create-user';
import { CreateKogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { UserFormRef } from 'pages/backoffice/features/users/user-form/types/userForm';
import { UserFormFeature } from 'pages/backoffice/features/users/user-form/UserForm.feature';
import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateUserModal = ({ open, onClose }: Props) => {
  const userFormRef = useRef<UserFormRef>(null);
  const { createUser } = useCreateUser();

  const handleCreateUser = useCallback(
    (data: CreateKogniaUser) => {
      createUser({
        data,
        onSuccess: onClose,
      });
    },
    [createUser, onClose],
  );

  const handleSubmit = useCallback(() => {
    userFormRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='New user'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={'Create user'}
      open={open}
      keepMounted
    >
      <UserFormFeature handleOnSubmit={handleCreateUser} ref={userFormRef} />
    </DialogNew>
  );
};
