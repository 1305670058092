import { nativeEnum, object, string, TypeOf } from 'zod';

import { MappingProvider } from 'pages/backoffice/types/shared';
import { TeamTypes } from 'shared/types/team/team';

export const teamMappingSchema = object({
  provider: nativeEnum(MappingProvider),
  team_id_provider: string().min(1),
});

export type TeamMapping = TypeOf<typeof teamMappingSchema>;

export interface TeamFixture {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date: Date;
  pitchLength: number;
  pitchWidth: number;
  id: string;
}

export interface MetadataTeamWithFixtures {
  name: string;
  abbreviation: string;
  logoUrl?: string;
  teamType: TeamTypes;
  countryCode?: string;
  id: string;
  fixtures: TeamFixture[];
  mappings?: TeamMapping[];
}

export interface TeamPlayer {
  jerseyNumber: number;
  side: number;
  position: number;
  name: string;
  id: string;
  photoUrl: string | null;
  teamId: string;
}

export interface TeamWithPlayers extends MetadataTeamWithFixtures {
  players: TeamPlayer[];
}

export type MetadataTeamsFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};
