import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaylistsFilterActions } from 'api/playlist/useFetchPlaylists';
import { PlaylistsFilters } from 'api/playlist/useFetchPlaylists/types';
import { SearchPlaylist } from 'pages/playlists/components/playlists-list/components/filters/search-playlist';
import { DateRange } from 'shared/components/date-range';
import { FilterLabel } from 'shared/components/filter-label';
import Filter, { FilterOption } from 'shared/components/filter-v2';
import CheckBoxMenu from 'shared/components/filter-v2-menu/checkbox';
import IconStrawberry from 'shared/components/icons/icon-strawberry';
import { FiltersList } from 'shared/types';

type FilterDropdownsProps = {
  filtersList: FiltersList;
  filterActions: PlaylistsFilterActions;
  appliedFilters: PlaylistsFilters;
};

enum DropDownPlaylistsFilters {
  DATE = 'date',
  COMPETITION = 'competition',
}

export const Filters = ({ appliedFilters, filtersList, filterActions }: FilterDropdownsProps): JSX.Element => {
  const [filters, setFilters] = useState<FiltersList>(filtersList);
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState<string>();

  useEffect(() => {
    if (!isEmpty(filtersList)) {
      setFilters(filtersList);
    }
  }, [filtersList]);

  const handleOpenFilter = useCallback(
    (filter: DropDownPlaylistsFilters) => {
      filter === openFilter ? setOpenFilter(undefined) : setOpenFilter(filter);
    },
    [openFilter],
  );

  return (
    <Stack direction={'row'} gap={2} justifyContent={'flex-end'} alignItems={'center'}>
      <FilterLabel>
        <IconStrawberry size='small' />
        {t('filters:title')}
      </FilterLabel>

      <DateRange
        from={appliedFilters.from}
        to={appliedFilters.to}
        hasSelectedOptions={Boolean(appliedFilters.from) && Boolean(appliedFilters.to)}
        isOpen={DropDownPlaylistsFilters.DATE === openFilter}
        onClick={() => handleOpenFilter(DropDownPlaylistsFilters.DATE)}
        onClose={(start, end) => {
          filterActions.setDateRange(start, end);
          setOpenFilter(undefined);
        }}
      />

      {filters[DropDownPlaylistsFilters.COMPETITION]?.options ? (
        <Filter
          isOpen={DropDownPlaylistsFilters.COMPETITION === openFilter}
          onClickOpen={() => handleOpenFilter(DropDownPlaylistsFilters.COMPETITION)}
          onClose={() => setOpenFilter(undefined)}
          displayName={t(`filters:competition`)}
          hasSelectedOptions={appliedFilters.competition.length > 0}
        >
          <CheckBoxMenu
            initialOptions={map(filters['competition'].options, (option, id) => ({
              id,
              title: option.title,
              isSelected: appliedFilters.competition.includes(id),
            }))}
            submitOptions={(options: FilterOption[]) => {
              setOpenFilter(undefined);
              filterActions.setCompetitions(options.filter((option) => option.isSelected).map((option) => option.id));
            }}
          />
        </Filter>
      ) : null}
      <SearchPlaylist onUpdate={filterActions.setName} />
    </Stack>
  );
};
