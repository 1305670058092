import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CompetitionInfo from 'pages/recordings-list/components/live-recording-card/competition-info';
import DateInfo from 'pages/recordings-list/components/live-recording-card/date-info';
import LiveRecordingInfo from 'pages/recordings-list/components/live-recording-card/live-recording-info';
import StartTime from 'pages/recordings-list/components/live-recording-card/match-day-info';
import RecordingCard from 'pages/recordings-list/components/recording-card';
import stylesCard from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import IconCamera from 'shared/components/icons/icon-camera';
import { MenuListOption } from 'shared/components/kebab-menu';
import { ListInfo } from 'shared/components/list/list-info';
import { ListItemTitle } from 'shared/components/list/list-item-title';
import { Recording } from 'shared/types/recording/types';
import { makeRecordingAlignmentSourcesRoute, makeRecordingDetailRoute } from 'tagging-tool/utility/navigation';

interface Props {
  recording: Recording;
}

const LiveRecordingCard = React.memo(({ recording }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { name, date, competitionName } = recording;

  const menuOptions = [
    {
      displayText: '',
    },
    {
      displayText: t('recording:actions.align-with-video'),
      icon: <IconCamera size='small' color='secondary' />,
      onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event && event.stopPropagation();
        navigate(
          makeRecordingAlignmentSourcesRoute({
            recordingId: recording.id,
            isLive: recording.isLive,
          }),
        );
      },
    },
  ] as MenuListOption[];

  const handleClickToTaggingTool = useCallback(() => {
    navigate(makeRecordingDetailRoute({ recordingId: recording.id, isLive: true, showsKeypad: true }));
  }, [navigate, recording.id]);

  return (
    <RecordingCard
      hasVideoSource
      menuOptions={menuOptions}
      onClick={handleClickToTaggingTool}
      recording={recording}
      title={
        <ListInfo>
          <LiveRecordingInfo />
          <ListItemTitle>
            <h2>{name}</h2>
            <CompetitionInfo competitionName={competitionName} />
          </ListItemTitle>
        </ListInfo>
      }
    >
      <div />
      <div className={stylesCard.recordingDate}>
        <DateInfo date={date} />
        <StartTime date={date} />
      </div>
      <div />
    </RecordingCard>
  );
});

LiveRecordingCard.displayName = 'LiveRecordingCard';

export default LiveRecordingCard;
