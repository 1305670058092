import { z } from 'zod';

import { playerBackendApiResponseSchema } from 'shared/types/player/playerBackendApiSchema';
import { playerFixturesSchema, playerMappingSchema, playerSchema } from 'shared/types/player/playerSchema';
import {
  playerSportMetadataApiResponseSchema,
  playerSportMetadataApiSchema,
} from 'shared/types/player/playerSportMetadataApiSchema';

export enum PlayerMappingProviders {
  KOGNIA = 'kognia',
  CHYRONHEGO = 'chyronhego',
  SECOND_SPECTRUM = 'second-spectrum',
  HAWKEYE = 'hawkeye',
  SIFNALITY = 'signality',
  SIFNALITY_KOGNIA = 'signality-kognia',
  SPORT_LOGIQ = 'sportlogiq',
  OPTA = 'opta',
  BUNDES_LIGA = 'bundesliga',
}

export type PlayerSportMetadataApi = z.infer<typeof playerSportMetadataApiSchema>;

export type PlayerSportMetadataApiResponse = z.infer<typeof playerSportMetadataApiResponseSchema>;

export type PlayerBackendApiResponse = z.infer<typeof playerBackendApiResponseSchema>;

export type Player = z.infer<typeof playerSchema>;

export type PlayerMapping = z.infer<typeof playerMappingSchema>;

export type PlayerFixture = z.infer<typeof playerFixturesSchema>;

export type PlayerApiFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};
